<template>

    <form v-if="form" class="space-y-6 w-full divide-y divide-gray-200 dark:divide-gray-700">

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="col-span-1">
                <Input name="name" placeholder="Vārds Uzvārds" v-model="form.name" :errors="errors.name"/>
            </div>

            <div class="col-span-1">
                <Input name="phone" placeholder="Telefona nr." v-model="form.phone" :errors="errors.phone"/>
            </div>

            <div class="col-span-1">
                <Input name="email" placeholder="E-pasts" v-model="form.email" :errors="errors.email"/>
            </div>

            <div class="col-span-1">
                <Input name="occupation" placeholder="Nodarbošanās" v-model="form.occupation"
                       :errors="errors.occupation"/>
            </div>

            <div class="col-span-1">
                <Input type="date" name="birth_date" placeholder="Nodarbošanās" v-model="form.birth_date"
                       :errors="errors.birth_date"/>
            </div>
        </div>

        <div class="col-span-1">
            <Checkbox class="md:mt-8" text="Atktīvs" v-model="form.is_active"/>
        </div>

        <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
            <template v-if="!loading">
                <button type="button"
                        class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                        @click.prevent="submit">
                    Saglabāt
                </button>
            </template>
            <template v-else>
                <Loading/>
            </template>

            <button @click.prevent="hideEditContactForm" type="button"
                    class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
                Atcelt
            </button>

        </div>

    </form>

</template>

<script>
import {mapGetters} from "vuex";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Checkbox from "../../Components/Checkbox";

export default {
    name: "EditContactDetails",
    data: () => ({
        form: null,
    }),
    props: ["contact"],
    components: {
        Input,
        Loading,
        Checkbox
    },
    mounted() {
        this.$store.dispatch("setErrors", {});
        this.form = this.contact
    },
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: 'errors',
        }),
        formValidation() {
            return {
                name: {
                    rules: ['required']
                },
            }
        },
    },
    methods: {
        hideEditContactForm() {
            this.$store.dispatch("clearCustomerErrorMessages");
            this.$store.dispatch("getSingleContact", this.contact.id);
            this.$store.dispatch("removeAllFormsForDisplay");
        },
        submit() {
            this.$Progress.start();

            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("updateContact", {
                    id: this.contact.id,
                    name: this.contact.name,
                    phone: this.contact.phone,
                    email: this.contact.email,
                    occupation: this.contact.occupation,
                    birth_date: this.contact.birth_date,
                    is_active: this.contact.is_active,
                });
            } else {
                this.$Progress.fail();
            }
        },
    },
}
</script>

<style>

</style>