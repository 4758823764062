<template>
  <Content :search="false">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
        <template v-if="branch">
          <h2>Filiāle {{branch.name}}</h2>
        </template>
      </div>
    </template>

    <template v-slot:topbar>
      <template v-if="branch && branch.customer.branches.length > 1 && this.$userCan('manage_customers')">
        <div class="flex flex-wrap items-center">
          <Button icon="delete" @click="deleteBranch(branch.id)" />
        </div>
      </template>
    </template>
    
    <template v-slot:content>
      <div>
        <template v-if="branch">
          <div class="sm:rounded-md mb-3">
            <ul role="list" class="">
              <BranchDetails :branch="branch" />

              <AllBranchContacts :contacts="branch.contacts" />

              <template v-if="branch.orders">
                <AllOrderHistory :data="branch.orders" />
              </template>

              <template v-if="branch.inventory_items && branch.inventory_items.length > 0">
                <BranchInventoryItems :items="branch.inventory_items" />
              </template>

            </ul>
          </div>
        </template>
      </div>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

const BranchDetails = defineAsyncComponent(() =>
  import('@/components/Customer/Branches/Branch/BranchDetails/BranchDetails'))
const AllBranchContacts = defineAsyncComponent(() =>
  import('@/components/Customer/Branches/Branch/BranchContacts/AllBranchContacts'))
const AllOrderHistory = defineAsyncComponent(() =>
  import('@/components/OrderHistory/AllOrderHistory'))
const BranchInventoryItems = defineAsyncComponent(() =>
  import('@/components/Customer/Branches/Branch/BranchInventoryItems'))

export default {
  name: "Branch",
  components: {
    BranchDetails,
    AllBranchContacts,
    AllOrderHistory,
    BranchInventoryItems,
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('clearBranch')
    this.$store.dispatch('getSingleBranch', this.$route.params.branchId)
  },
  computed: {
    ...mapGetters({
      branch: 'branch',
    })
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    deleteBranch(branchId){
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteBranch', {
          branchId: branchId,
          customerId: this.branch.customer.id
        })
      }
    }
  }
}
</script>

<style>

</style>