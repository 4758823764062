<template>
  <item-text title="Vārds Uzvārds" :text="contact.name" />
  <item-text title="Telefona nr." :text="contact.phone ? contact.phone :  'Nav norādīts'" />
  <item-text title="E-pasts" :text="contact.email ? contact.email :  'Nav norādīts'" />
  <item-text title="Nodarbošanās" :text="contact.occupation ? contact.occupation :  'Nav norādīts'" />
  <item-text title="Dzimšanas datums" :text="contact.birth_date ? (contact.birth_date | moment('DD / MM / YYYY')) : 'Nav norādīts'" />
  <item-text title="Lietotājs" :text="contact.user ? contact.user.email :  'Nav pievienots'" />
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "ShowContactDetails",
  components: {
    ItemText
  },
  props: {
    contact: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style>
</style>