<template>
    <item-card>
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                Pamatdati
            </h3>
        </template>
        <template v-slot:buttons>


            <template v-if="this.$userCan('manage_customers')
                      && !displayForm('EditContactDetails')
                      && !displayForm('AddUserToContact')"
            >
                <Button v-if="!contact.has_user " icon="account-plus" @click="showForm('AddUserToContact')" tooltip="Pievienot lietotāja tiesības"/>

                <Button v-if="contact.has_user " icon="email-alert" @click="sendPasswordEmail" tooltip="Izsūtīt piekļuves epastu"/>

                <Button v-if="contact.has_user " icon="account-minus" @click="removeUserFromContact" tooltip="Atcelt lietotāja tiesības"/>

                <Button icon="pencil" @click="showForm('EditContactDetails')" />
            </template>

        </template>
        <template v-slot:content>
            <ShowContactDetails v-if="!displayForm('EditContactDetails') && !displayForm('AddUserToContact')"
                                :contact="contact"/>
            <EditContactDetails v-if="displayForm('EditContactDetails')" :contact="contact"/>
            <AddUserToContact v-if="displayForm('AddUserToContact')" :contact="contact"/>
        </template>
    </item-card>
</template>

<script>
import {mapGetters} from 'vuex'
import ItemCard from "@/components/Components/ItemCard"
import ShowContactDetails from "@/components/Customer/Contact/ShowContactDetails"
import EditContactDetails from "@/components/Customer/Contact/EditContactDetails"
import AddUserToContact from "./AddUserToContact";
import Button from "../../Components/Button";


export default {
    name: "ContactDetails",
    components: {
        ItemCard,
        ShowContactDetails,
        EditContactDetails,
        AddUserToContact,
        Button
    },
    props: {
        contact: {
            type: Object,
            required: true,
        }
    },
    data: () => ({
        showDetails: false
    }),
    computed: {
        ...mapGetters({
            formsForDisplay: 'formsForDisplay',
        })
    },
    methods: {
        showForm(formName) {
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName) {
            return this.formsForDisplay.includes(formName)
        },
        removeUserFromContact(){
            if(confirm('Vai esi drošs?')){
                this.$Progress.start()
                this.$store.dispatch("removeUserFromContact", this.contact.id);
            }
        },
        sendPasswordEmail() {
            this.$Progress.start()
            this.$store.dispatch("sendUserEmail", this.contact.user.id);
        }
    }
}
</script>

<style>

</style>