<template>
  <router-link v-if="customer" :to="`/customers/${customer.id}`" class="block hover:bg-gray-100 dark:hover:bg-gray-770 w-full rounded-lg">
    <div class="py-3 px-2">
      <div class="sm:flex flex-wrap lg:flex-nowrap items-center">
        <div class="grid grid-cols-12 w-full">
          <div class="col-span-12 sm:col-span-6">
            <ItemText :text="customer.name" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3">
            <ItemText :text="customer.region ? customer.region.name : 'Reģions nav norādīts'" />
          </div>
        </div>
        
        <div class="ml-auto">
          <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
            <button class="text-gray-400 flex items-center whitespace-nowrap">
              Skatīt klientu
              <ChevronRightIcon class="w-6 h-6" />
            </button>
          </p>
        </div>

      </div>
    </div>
  </router-link>
</template>

<script>
import ItemText from "@/components/Components/ItemText"
import {
  ChevronRightIcon,
} from "@heroicons/vue/solid";

export default {
  name: "ShowCustomer",
  components: {
    ItemText,
    ChevronRightIcon,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style>

</style>