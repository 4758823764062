<template>
    <form v-if="form" class="space-y-6 w-full ">

        <div class="grid grid-cols-1 ">

            <div class="col-span-1">
                <Input name="email" placeholder="Lietotāja E-pasts" v-model="form.email" :errors="errors.email" />
            </div>
        </div>

        <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
            <template v-if="!loading">
                <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
                    Saglabāt
                </button>
            </template>
            <template v-else>
                <Loading />
            </template>

            <button @click.prevent="hideForm()" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
                Atcelt
            </button>

        </div>

    </form>
</template>

<script>
import {mapGetters} from "vuex";
import Input from "../../Components/Input";
import Loading from "../../Components/Loading";
import Validation from "@/modules/Validation";

export default {
    name: "AddUserToContact",
    data: () => ({
        form: null,
    }),
    props: ["contact"],
    components: {
        Input,
        Loading
    },
    mounted() {
        this.$store.dispatch("setErrors", {});
        this.form = this.contact
    },
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: 'errors',
        }),
        formValidation() {
            return {
                email: {
                    rules: ['required', 'email']
                },
            }
        },
    },
    methods: {
        hideForm() {
            this.$store.dispatch("clearCustomerErrorMessages");
            this.$store.dispatch("getSingleContact", this.contact.id);
            this.$store.dispatch("removeAllFormsForDisplay");
        },
        submit() {
            this.$Progress.start();

            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("addUserToContact", {
                    id: this.contact.id,
                    data: {
                        email: this.contact.email
                    }
                });
            } else {
                this.$Progress.fail();
            }
        },
    },
}
</script>

<style scoped>

</style>