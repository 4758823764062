<template>
    <div class="relative z-30" @mouseover="tooltip = true" @mouseleave="tooltip = false">
      <slot name="content" />

      <div class="absolute bottom-full transform w-auto text-center whitespace-nowrap" :class="[right ? 'right-0' : 'left-1/2 -translate-x-1/2', bottom ? 'top-full' : '']" v-show="tooltip && text">
        <div class="bg-gray-500 opacity-90 text-white text-base rounded py-1 px-4 right-0 bottom-full">
          {{text}}
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Tooltip",
  data() {
    return {
      tooltip: false
    }
  },
  props: {
    text: {
      type: String,
      default: null
    },
    right: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>