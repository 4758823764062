<template>
  <Content :search="false">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
        <template v-if="contact">
          <h2>Kontakts {{ contact.name }}</h2>
        </template>
      </div>
    </template>
    
    <template v-slot:content>
      <div>
        <template v-if="contact">
          <div class="sm:rounded-md mb-3">
            <ul role="list" class="">

              <ContactDetails :contact="contact" />
              
              <AllCustomers :customers="contact.customers" />

              <AllBranches :branches="contact.branches" />

            </ul>
          </div>
        </template>
      </div>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactDetails from "@/components/Customer/Contact/ContactDetails"
import AllCustomers from "@/components/Customer/Contact/Customers/AllCustomers"
import AllBranches from "@/components/Customer/Contact/Branches/AllBranches"

export default {
  name: "Contact",
  components: {
    ContactDetails,
    AllCustomers,
    AllBranches
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('clearBranch')
    this.$store.dispatch('getSingleContact', this.$route.params.contactId)
  },
  computed: {
    ...mapGetters({
        contact: 'singleContact',
    })
  },
  methods: {
    goBack(){
        this.$router.go(-1)
    },
    deleteContact(branchId){
        if(confirm('Vai esi drošs?')){
            this.$Progress.start()
            this.$store.dispatch('deleteBranch', {
                branchId: branchId,
                customerId: this.branch.customer.id
            })

        }
    }
  }
}
</script>

<style>

</style>