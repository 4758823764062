<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Filiāles
      </h3>
    </template>

    <template v-slot:content>
      
      <template v-if="branches.length > 0">
        <template v-for="(branch, index) in branches" :key="index">
          <ShowBranch :branch="branch"/>
        </template>
      </template>

      <template v-else>
        <div class="w-full">
          <p class="text-center text-lg text-gray-400 mb-3">Kontaktam nav pievienotas filiāles</p>
        </div>
      </template>

    </template>
  </item-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemCard from "@/components/Components/ItemCard"
import ShowBranch from "@/components/Customer/Branches/ShowBranch"

export default {
  name: "AllBranches",
  components: {
    ItemCard,
    ShowBranch,
  },
  props: {
    branches: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
        customer: 'customer',
        formsForDisplay: 'formsForDisplay',
    })
  },
  methods: {
    showDivider(index) {
      return (this.branches.length - 1) !== index
    },
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  }
}
</script>

<style>

</style>