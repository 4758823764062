<template>
  <Tooltip :text="tooltip" :right="tooltipRight" :bottom="tooltipBottom">
    <template v-slot:content>
      <button
        type="button"
        @click="clickFn"
        @touchstart="clickFn"
        class="
          text-gray-400
          hover:bg-gray-100
          dark:hover:bg-gray-700
          px-2
          py-2
          rounded-full
          flex items-center
        "
        :class="className"
      >
        <template v-if="icon">
          <component
            :is="getIcon(icon)"
            class="w-6 h-6"
          />
        </template>
        <template v-if="text">{{text}}</template>
      </button>

    </template>
  </Tooltip>
</template>

<script>
import Tooltip from "@/components/Components/Tooltip";

import {
  PencilIcon,
  TrashIcon,
  PlusIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  UserAddIcon,
  UserRemoveIcon,
  MailIcon,
  PrinterIcon,
  DocumentTextIcon,
  TrendingUpIcon,
  PlusCircleIcon,
  MinusCircleIcon,
  PencilAltIcon,
  DownloadIcon,
  ChatAltIcon,
  SwitchHorizontalIcon,
  RefreshIcon,
  SelectorIcon,
  DuplicateIcon,
  ChevronRightIcon,
  XIcon,
  AnnotationIcon,
  ArrowLeftIcon,
  ArrowCircleUpIcon,
  ArrowCircleDownIcon,
  SortDescendingIcon,
  SortAscendingIcon,
  ExternalLinkIcon,
  QuestionMarkCircleIcon,
  AtSymbolIcon,
  MailOpenIcon,
  FolderDownloadIcon,
  EyeIcon,
  ClipboardCopyIcon,
  ReceiptTaxIcon,
} from "@heroicons/vue/solid";
import {
  TruckIcon,
  FolderDownloadIcon as FolderDownloadOutlineIcon,
  DocumentDownloadIcon,
  CashIcon,
  ClipboardListIcon,
  BookOpenIcon,
  ClipboardCopyIcon as CopyIcon,
} from "@heroicons/vue/outline";

export default {
  name: "Button",
  components: {
    Tooltip,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    tooltipRight: {
      type: Boolean,
      default: false,
    },
    tooltipBottom: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    }
  },
  emits: ["click"],
  methods: {
    clickFn() {
      this.$emit("click");
    },
    getIcon(name) {
      const icons = {
        pencil: PencilIcon,
        delete: TrashIcon,
        plus: PlusIcon,
        "chevron-down": ChevronDownIcon,
        "chevron-up": ChevronUpIcon,
        "account-plus": UserAddIcon,
        "account-multiple-plus": UserAddIcon,
        "account-minus": UserRemoveIcon,
        "account-multiple-minus": UserRemoveIcon,
        "account-switch": SwitchHorizontalIcon,
        "email-alert": MailIcon,
        printer: PrinterIcon,
        "text-box": DocumentTextIcon,
        "call-merge": TrendingUpIcon,
        "briefcase-plus": PlusCircleIcon,
        "briefcase-minus": MinusCircleIcon,
        "briefcase-edit": PencilAltIcon,
        "briefcase-download": DocumentDownloadIcon,
        download: DownloadIcon,
        message: ChatAltIcon,
        sync: RefreshIcon,
        "arrow-decision": SelectorIcon,
        "animation-play-outline": DuplicateIcon,
        "chevron-right": ChevronRightIcon,
        "close": XIcon,
        "comment": AnnotationIcon,
        "cash-multiple": CashIcon,
        "arrow-left": ArrowLeftIcon,
        "arrow-up-circle": ArrowCircleUpIcon,
        "arrow-down-circle": ArrowCircleDownIcon,
        "sort-bool-descending-variant": SortDescendingIcon,
        "sort-bool-ascending-variant": SortAscendingIcon,
        "open-in-new": ExternalLinkIcon,
        "sitemap": ClipboardListIcon,
        "email-edit": MailOpenIcon,
        "at": AtSymbolIcon,
        "briefcase-download-outline": DocumentDownloadIcon,
        "download-folder": FolderDownloadOutlineIcon,
        "download-box-outline": FolderDownloadIcon,
        "book-open": BookOpenIcon,
        "eye": EyeIcon,
        "clipboard-arrow-left": ClipboardCopyIcon,
        "receipt": ReceiptTaxIcon,
        "truck": TruckIcon,
        "copy": CopyIcon,
        "clipboard-copy": CopyIcon,
      };

      if (!icons[name]) {
        return QuestionMarkCircleIcon
      }

      return icons[name];
    },
  },
};
</script>

<style>
</style>